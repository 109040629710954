import React from 'react';
import './Mededeling.css';  // Import styles for the Alert component

function Mededeling() {
  return (
   

<div class="container-mededeling">
    <p class="tekst">Afspraken worden enkel gepland via een telefoontje!</p>

</div>


  );
}

export default Mededeling;